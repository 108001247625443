import { useMutation, UseMutationResult, useQuery, UseQueryResult } from '@tanstack/react-query';
import { getTokenAndHeaders, IAuthParams, useAuthParams } from 'nulia-ui';
import { EApiQueryKey } from '../types/enums/EApiQueryKey';
import { apiInstance } from './util/apiInstance';
import { mapCustomers, mapResellers } from './mappers';
import { IAccountDetailsVM } from '../interfaces/views/IAccountDetailsVM';
import { IPostAddResellerDTO } from '../interfaces/dtos/IPostAddResellerDTO';
import { IResellerVM } from '../interfaces/views/IResellerVM';
import { ICustomerVM } from '../interfaces/views/ICustomerVM';
import { IPostAddCustomerDTO } from 'interfaces/dtos/IPostAddCustomerDTO';

// GET ACCOUNT DETAILS
export const getAccountDetails = async (authParams: IAuthParams) => {
    // const { headers } = await getTokenAndHeaders(authParams);
    // const { data } = await apiInstance.get(`accounts`, headers);
    return [];
};

export const useGetAccountDetailsQuery: () => UseQueryResult<IAccountDetailsVM[], Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.ACCOUNTS_DETAILS_FETCH],
        queryFn: async () => {
            return getAccountDetails(authParams);
        },
        refetchOnWindowFocus: false,
        enabled: false
    });
};

// POST ADD RESELLER
export const postAddReseller: (
    variables: IPostAddResellerDTO & { authParams: IAuthParams }
) => Promise<undefined> = async (variables) => {
    const { authParams, ...addResellerRequestData } = variables;
    const { headers } = await getTokenAndHeaders(authParams);

    const {
        data: { data }
    } = await apiInstance.post(`folder`, addResellerRequestData, headers);
    return data;
};

export const usePostAddReseller: () => UseMutationResult<undefined, Error, IPostAddResellerDTO> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: (variables: IPostAddResellerDTO) => {
            return postAddReseller({ ...variables, authParams });
        }
    });
};

// GET PARTNER RESELLERS
export const getPartnerResellers = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: {
            data: { folders }
        }
    } = await apiInstance.get(`folder`, headers);
    return mapResellers(folders);
};

export const useGetPartnerResellersQuery: () => UseQueryResult<IResellerVM[], Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.ACCOUNTS_FETCH_RESELLERS],
        queryFn: async () => {
            return getPartnerResellers(authParams);
        },
        refetchOnWindowFocus: false
    });
};

// GET PARTNER CUSTOMER
export const getPartnerCustomers = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: {
            data: { customers }
        }
    } = await apiInstance.get(`customer`, headers);
    return mapCustomers(customers);
};

export const useGetPartnerCustomersQuery: () => UseQueryResult<ICustomerVM[], Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.ACCOUNTS_FETCH_CUSTOMERS],
        queryFn: async () => {
            return getPartnerCustomers(authParams);
        },
        refetchOnWindowFocus: false
    });
};

// POST ADD CUSTOMER
export const postAddCustomer: (
    variables: IPostAddCustomerDTO & { authParams: IAuthParams }
) => Promise<undefined> = async (variables) => {
    const { authParams, ...addCustomerRequestData } = variables;
    const { headers } = await getTokenAndHeaders(authParams);

    const {
        data: { data }
    } = await apiInstance.post(`customer`, addCustomerRequestData, headers);
    return data;
};

export const usePostAddCustomer: () => UseMutationResult<undefined, Error, IPostAddCustomerDTO> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: (variables: IPostAddCustomerDTO) => {
            return postAddCustomer({ ...variables, authParams });
        }
    });
};
